import {get} from "lodash";

export function getStoreCredtBalanceFromUser(user) {
    const openChargeCardInstance = _.get(
      user,
      "loyaltyProfile.data.openChargeCardInstance",
    );
  
    const amountOnCard = openChargeCardInstance
      ? openChargeCardInstance.loadedAmount - openChargeCardInstance.usedAmount
      : 0;
  
    return amountOnCard.toFixed(2);
  }