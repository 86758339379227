import { Box, Drawer, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "../../AppContainer/mui-theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import classnames from "classnames";
import _, { find, get, includes, isEmpty, map, pick } from "lodash";
import React, { useCallback, useState } from "react";
import { getParams, navigateTo, setParams } from "../../../utils/location";
import { getAppMedia, mediaTypes } from "../../../utils/media";
import AppContainer from "../../AppContainer";
import BackgroundImage from "../../BackgroundImage";
import BackgroundImageExtrnalLink from "../../BackgroundImageExtrnalLink";
import BrandFooter from "../../BrandFooter";
import Button from "../../Button";
import HomeTitle from "../../HomeTitle";
import InfoIcon from "../../icons/Info.svg";
import LearnMoreIcon from "../../icons/LearnMore.svg";
import TableIcon from "../../icons/Table.svg";
import LABELS from "../../labels";
import NewOrPreviousOrderModal from "../../NewOrPreviousOrderModal";
import Promotion from "../../promotion";
import PromotionsAvatars from "../../promotion/promotions-avatars";
import HomeButtons from "../HomeButtons";
import * as styles from "./index.module.scss";
import {HOMEPAGE_TEMPLATE_KEY} from "../../../utils/constants";
import HomePoints from "../../HomePoints";
import {isTemplateEnabled} from "../../../logic/templates/isTemplateEnabled";
import {isNewsfeedTemplateLayout} from "../../../logic/templates/isNewsfeedTemplateLayout";

function TitleComponent({ user, appStyles, pointBankCoupons, homepageImageSplit, enableFeedback, T }) {
  const renderHomePointsComponent = isTemplateEnabled(appStyles, HOMEPAGE_TEMPLATE_KEY.loyalty)  && user.loggedIn;

  if (renderHomePointsComponent) {
    return (
      <HomePoints
        T={T}
        title={T("Welcome to")}
        desktopExtraTitle={T("{{desktop_extra_title}}")}
        appStyles={appStyles}
        className={classnames(!homepageImageSplit && enableFeedback && styles.HomeTitle)}
        user={user}
        pointBankCoupons={pointBankCoupons}
      />
    )
  }

  return (
    <HomeTitle
      T={T}
      title={T("Welcome to")}
      desktopExtraTitle={T("{{desktop_extra_title}}")}
      appStyles={appStyles}
      className={classnames(!homepageImageSplit && enableFeedback && styles.HomeTitle)}
    />
  )
}

export default function DefaultTemplate({
  startNewOrder,
  order,
  T,
  appStyles,
  promotions,
  pageContext,
  user,
  location,
  openAuthView,
}) {
  const { servingOptions, branches, businessAppConfiguration, pointBankCoupons } = pageContext;
  const { classes } = useStyles();
  const [startNewOrderModalOpen, setStartNewOrderModalOpen] = useState(false);
  const [openDesktopPromotions, setOpenDesktopPromotions] = useState(false);
  const handleOpenPromotions = () => setOpenDesktopPromotions(true);
  const handleClosePromotions = () => setOpenDesktopPromotions(false);

  const { orderItems, servingOptionType, branchId } = order;

  const navigateToTargetOrLoginMobile = useCallback(
    ({ type, target }) => () => {
      user.loggedIn ? navigateTo(target) : openAuthView(type);
    },
    [openAuthView, user.loggedIn],
  );

  const navigateToTargetOrLoginDesktop = useCallback(
    ({ type, target }) => () => {
      user.loggedIn ? navigateTo(target) : openAuthView(type);
    },
    [openAuthView, user.loggedIn],
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const skipServingOptionsPage = get(appStyles, "skipServingOptionsPage", false);

  const navigateToNewOrder = useCallback(
    () =>
      navigateTo(
        skipServingOptionsPage || servingOptions.length < 2 ? "/find-location" : "/serving-options",
      ),
    [],
  );

  const handleStartNewOrderClicked = useCallback(
    (e) => {
      e.preventDefault();
      const isDuringOrder = !isEmpty(orderItems);
      if (isDuringOrder) {
        setStartNewOrderModalOpen(true);
      } else {
        startNewOrder();
        navigateToNewOrder();
      }
    },
    [orderItems],
  );

  const closeStartNewOrderModal = useCallback(
    () => setStartNewOrderModalOpen(false),
    [],
  );

  const onUserConfirmStartNewOrder = useCallback(() => {
    closeStartNewOrderModal();
    startNewOrder();
    navigateToNewOrder();
  }, [startNewOrder]);

  const continueOrder = useCallback(() => {
    closeStartNewOrderModal();
    if (order.branchId) {
      navigateTo(
        setParams("/order", pick(order, ["branchId, servingOptionType"])),
      );
    } else if (order.servingOptionType) {
      navigateTo(
        setParams("/find-location", pick(order, ["servingOptionType"])),
      );
    } else {
      navigateToNewOrder();
    }
  }, [order.branchId, order.servingOptionType]);

  const disableRewards =
    get(businessAppConfiguration, "disableRewards") &&
    !appStyles.rewardsAlternative;
  const enableFeedback = get(businessAppConfiguration, "enableFeedback");
  const requireLoginForGiftCard = get(
    businessAppConfiguration,
    "requireLoginForGiftCard",
  );
  const giftCardsEnabled = get(businessAppConfiguration, "giftCardsEnabled");

  const showGiftCard = giftCardsEnabled;

  const showRestaurantInteractionBlock = get(appStyles, "homepageRestaurantInteractionBar", true);

  const orderSelectedServingOption =
    find(servingOptions, { type: servingOptionType }) || {};
  const orderSelectedBranch = find(branches, { id: branchId }) || {};

  const { links = {} } = appStyles;

  const split = appStyles.homepageImageSplit;
  const tiles = isMobile && appStyles.homepageTileButtons;
  const webAppBackground =
    getAppMedia(mediaTypes.webAppBackground, appStyles) || {};

  const webMobileAppBackground =
    getAppMedia(mediaTypes.webMobileAppBackground, appStyles) ||
    webAppBackground ||
    {};

  const isSSR = typeof window === "undefined";

  const isNative = !isSSR && window.isNativeApp;

  const disableReorder = includes(appStyles.disabledFeatures, "reorder");
  const disableOrder = includes(appStyles.disabledFeatures, "order");
  const hasTwoButtons = disableRewards || (disableReorder && disableOrder);
  const newsfeedTemplateIsSelected = isNewsfeedTemplateLayout(appStyles)
  const backgroundImageHeight = getBackgroundImageHeight({
    split,
    tiles,
    isNative,
    hasTwoButtons,
    newsfeedTemplateIsSelected,
  });


  const showMobilePromotions =
    newsfeedTemplateIsSelected && isMobile && !isEmpty(promotions);

  const showDesktopPromotions =
    newsfeedTemplateIsSelected && !isMobile && !isEmpty(promotions);

  return (
    <>
      <AppContainer.Content appStyles={appStyles} classNames={styles.Content}>
        <NewOrPreviousOrderModal
          T={T}
          appStyles={appStyles}
          onChooseNewOrder={onUserConfirmStartNewOrder}
          onChooseContinueOrder={continueOrder}
          modalOpen={startNewOrderModalOpen}
          closeModal={closeStartNewOrderModal}
          orderSelectedServingOption={orderSelectedServingOption}
          orderSelectedBranch={orderSelectedBranch}
        />

        <HomeTitle
          T={T}
          title={T("{{desktop_title_prefix}}")}
          desktopExtraTitle={T("{{desktop_extra_title}}")}
          appStyles={appStyles}
          forDesktop={true}
          className={classnames(!split && enableFeedback && styles.HomeTitle && styles.HideOnMobile)}
        />

        <BackgroundImage
          noAnimation
          mediaType={mediaTypes.webMobileAppBackground}
          mediaKey={webMobileAppBackground.imageKey}
          sizes="768px"
          imagePreview={webMobileAppBackground.imagePreview}
          classNames={styles.BackgroundImage}
          split={split}
          tiles={tiles}
          height={backgroundImageHeight}
        >
          <TitleComponent
            T={T}
            appStyles={appStyles}
            user={user}
            pointBankCoupons={pointBankCoupons}
            homepageImageSplit={appStyles.homepageImageSplit}
            enableFeedback={enableFeedback}
          />

          <AppContainer.AttachedContent
            classNames={
              split ? styles.MobileBottomButtons : styles.TopSideMobileButtons
            }
          >
            {map(appStyles.externalLinks, (link, indx) => (
              <Button
                key={link + indx}
                slim
                centered
                appStyles={appStyles}
                isExternalLink
                to={link.href}
                classNames={styles.ButtonWithIcon}
              >
                {T(link.label)}
                {link.useLearnMoreIcon ? <LearnMoreIcon /> : <TableIcon />}
              </Button>
            ))}
            {showRestaurantInteractionBlock && (
              <>
                {enableFeedback &&
                  (appStyles.contactUsAlternative?.href ? (
                    <Button
                      slim
                      centered
                      appStyles={appStyles}
                      isExternalLink
                      to={appStyles.contactUsAlternative.href}
                      // classNames={styles.ButtonWithIcon}
                    >
                      {T(
                        appStyles.contactUsAlternative.label ||
                          LABELS.CONTACT_US
                      )}
                    </Button>
                  ) : (
                    <Button
                      appStyles={appStyles}
                      to="/contact-us"
                      centered
                      slim
                    >
                      {T(LABELS.CONTACT_US)}
                    </Button>
                  ))}
                {showGiftCard && (
                  <Button
                    appStyles={appStyles}
                    to={setParams("/gift-card", { onCloseTarget: "/" })}
                    centered
                    slim
                  >
                    {T("Send a Gift Card")}
                  </Button>
                )}
              </>
            )}
          </AppContainer.AttachedContent>
        </BackgroundImage>

        {!isSSR && (
          <HomeButtons
            T={T}
            isSSR={isSSR}
            showMobilePromotions={showMobilePromotions}
            appStyles={appStyles}
            loggedIn={user.loggedIn}
            handleStartNewOrderClicked={handleStartNewOrderClicked}
            isMobile={isMobile}
            disableRewards={disableRewards}
            amountOfGiftsUnseen={user.amountOfGiftsUnseen}
            navigateToTargetOrLoginDesktop={navigateToTargetOrLoginDesktop}
            navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
          />
        )}

        {showMobilePromotions && (
          <Box padding={2}>
            <Grid container direction="column" spacing={2}>
              {map(promotions, (promotion, indx) => (
                <Grid item key={indx}>
                  <Promotion
                    promotion={promotion}
                    appStyles={appStyles}
                    navigateToTargetOrLoginDesktop={
                      navigateToTargetOrLoginDesktop
                    }
                    navigateToTargetOrLoginMobile={
                      navigateToTargetOrLoginMobile
                    }
                    handleStartNewOrderClicked={handleStartNewOrderClicked}
                    T={T}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </AppContainer.Content>
      <AppContainer.SecondaryContent relative>
        <AppContainer.AttachedContent
          classNames={classnames(
            styles.TopSideButtons,
            appStyles.rtl && styles.RTL,
          )}
        >
          {showRestaurantInteractionBlock && (
            <>
              {enableFeedback &&
                (appStyles.contactUsAlternative?.href ? (
                  <BackgroundImageExtrnalLink
                    appStyles={appStyles}
                    slim
                    to={appStyles.contactUsAlternative.href}
                  >
                    {T(
                      appStyles.contactUsAlternative.label ||
                        LABELS.CONTACT_US
                    )}
                  </BackgroundImageExtrnalLink>
                ) : (
                  <Button
                    appStyles={appStyles}
                    to="/contact-us"
                    slim
                    centered
                  >
                    {T(LABELS.CONTACT_US)}
                  </Button>
                ))}

              {showGiftCard && (
                <Button
                  appStyles={appStyles}
                  to={setParams("/gift-card", { onCloseTarget: "/" })}
                  centered
                  slim
                >
                  {T("Send a Gift Card")}
                </Button>
              )}
            </>
          )}

          {showDesktopPromotions && (
            <PromotionsAvatars
              open={openDesktopPromotions}
              promotions={promotions}
              onOpen={handleOpenPromotions}
              onClose={handleClosePromotions}
            />
          )}

          {showDesktopPromotions && (
            <Drawer
              className={classes.drawer}
              variant="persistent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="right"
              open={openDesktopPromotions}
            >
              <Box padding={2}>
                <Grid container direction="column" spacing={2}>
                  {map(promotions, (promotion, indx) => (
                    <Grid item key={indx}>
                      <Promotion
                        promotion={promotion}
                        appStyles={appStyles}
                        navigateToTargetOrLoginDesktop={
                          navigateToTargetOrLoginDesktop
                        }
                        navigateToTargetOrLoginMobile={
                          navigateToTargetOrLoginMobile
                        }
                        handleStartNewOrderClicked={handleStartNewOrderClicked}
                        T={T}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Drawer>
          )}
        </AppContainer.AttachedContent>

        {links.nutrition && (
          <BackgroundImageExtrnalLink
            appStyles={appStyles}
            to={links.nutrition}
          >
            <InfoIcon />
            {T("See our nutrition facts")}
          </BackgroundImageExtrnalLink>
        )}
        {appStyles.scanAlternative && (
          <BackgroundImageExtrnalLink
            appStyles={appStyles}
            to={appStyles.scanAlternative.href}
            bottom
            classNames={styles.ScanAlternative}
          >
            {appStyles.scanAlternative.useLearnMoreIcon ? (
              <LearnMoreIcon style={{ width: 37, height: "auto" }} />
            ) : (
              <TableIcon />
            )}{" "}
            {T(appStyles.scanAlternative.label)}
          </BackgroundImageExtrnalLink>
        )}
        {map(appStyles.externalLinks, (link, indx) => (
          <BackgroundImageExtrnalLink
            key={link + indx}
            appStyles={appStyles}
            bottom
            to={link.href}
            classNames={styles.ScanAlternative}
          >
            {link.useLearnMoreIcon ? <LearnMoreIcon /> : <TableIcon />}
            {T(link.label)}
          </BackgroundImageExtrnalLink>
        ))}
        <BackgroundImage
          mediaType={mediaTypes.webAppBackground}
          mediaKey={webAppBackground.imageKey}
          sizes="1920px"
          imagePreview={webAppBackground.imagePreview}
          noAnimation
        />
      </AppContainer.SecondaryContent>
      <BrandFooter
        T={T}
        appStyles={appStyles}
        backPath="/"
        params={getParams(location)}
      />
    </>
  );
}

const getBackgroundImageHeight = ({
  split,
  tiles,
  isNative,
  hasTwoButtons,
  newsfeedTemplateIsSelected,
}) => {
  if (newsfeedTemplateIsSelected) {
    return "100vw";
  }
  if (!split) {
    return "calc(100% - 32px)";
  }
  if (split && tiles) {
    if (isNative) {
      return "calc(100% - 2 * (50vw - 2 * 32px) - 60px -  60px)";
    } else {
      return "calc(100% - 2 * (50vw - 2 * 32px) - 60px - 60px - 32px - 16px - 10px)";
    }
  }

  if (isNative) {
    return `calc(100% - 212px - 60px - 12px - 32px${
      hasTwoButtons ? " + 72px" : ""
    })`;
  } else {
    return `calc(100% - 212px - 60px - 12px - 60px - 20px${
      hasTwoButtons ? " + 72px" : ""
    })`;
  }
};

const drawerWidth = 376;

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    position: "absolute",
    top: 120,
    height: `calc(100vh - 260px)`,
    borderLeft: "none",
    width: drawerWidth,
    backgroundColor: "transparent",
    WebkitMaskImage:
      "-webkit-gradient(linear, right 96%, right bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
  },
}));
