import React from "react";
import classnames from "classnames";
import {getPointsBalanceFromUser} from "../../../logic/user/getters/getPointsBalanceFromUser";
import { filter, uniq, sortBy} from 'lodash';
import * as styles from "./index.module.scss";
function filterNextMilestones(pointBankCoupons, rewardPointsBalance) {
  if (!pointBankCoupons)  {
    return [];
  }

  return filter(pointBankCoupons, ((coupon) => coupon.price - rewardPointsBalance > 0));
}

function limitArrayTo6(array) {
  if (array.length > 6) {
    const firstFive = array.slice(0, 5);
    const lastElement = array[array.length - 1];
    return [...firstFive, lastElement];
  }
  return array;
}

function formatPrice(price) {
  if (price < 1000) {
    return price.toString(); // Return the price as is if less than 1000
  } else if (price < 1000000) {
    return (price / 1000).toFixed(1) + 'K'; // Convert to 'K' for thousands
  } else if (price < 1000000000) {
    return (price / 1000000).toFixed(1) + 'M'; // Convert to 'M' for millions
  } else {
    return (price / 1000000000).toFixed(1) + 'B'; // Convert to 'B' for billions
  }
}

function Label({ price, maxPrice, isLast}) {
  const left = ((price / maxPrice * 100));
  const leftStr = `calc(${left}%)`
  const formattedPrice = formatPrice(price);
  return (
    <>
      <div className={classnames(styles.label )} style={{ left: leftStr}}>
        <div className={styles.price}>
          {formattedPrice}
        </div>
        { !isLast && (
          <div className={styles.marker} />
        )}
      </div>
    </>
  )
}

export default ({
  user,
  pointBankCoupons
}) => {
  const rewardPointsBalance = getPointsBalanceFromUser(user) || 0;
  const couponMilestones = filterNextMilestones(pointBankCoupons, rewardPointsBalance);
  const sortedAndLimitedCouponMilestones = limitArrayTo6(sortBy(couponMilestones, c => c.price));
  const couponMilestonesPrices = uniq(sortedAndLimitedCouponMilestones.map(c => c.price));
  const nextCouponMilestone = sortedAndLimitedCouponMilestones[0];
  const lastCouponMilestone = sortedAndLimitedCouponMilestones[sortedAndLimitedCouponMilestones.length - 1];
  const pointsToNextMilestone = nextCouponMilestone ? nextCouponMilestone.price - rewardPointsBalance : 0;
  if (!nextCouponMilestone || !lastCouponMilestone) {
    return null;
  }

  const width = (rewardPointsBalance / lastCouponMilestone.price) * 100;

  return (
    <div className={styles.loyaltyTracker}>
      <div className={styles.milestoneInfo}>
        {pointsToNextMilestone} points until your next reward
      </div>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
          <div className={styles.barBackground}>
            <div className={styles.barFill} style={{width: `${width}%`}} />
          </div>
          <div className={styles.startLabel}>0</div>
          {
            couponMilestonesPrices.map((price, index) => (
              <Label price={price} maxPrice={lastCouponMilestone.price} isLast={index === couponMilestonesPrices.length-1} />
            ))
          }
          {/*<div className={styles.endLabel}>{lastCouponMilestone.price}</div>*/}
        </div>
      </div>
    </div>
  );
};
